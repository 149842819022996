import { useState, useEffect } from 'react'
import { RecommendationsWithoutProduct } from '@vp/recommendations-products-lite'
import { PricingContextProvider } from '@vp/react-pricing-context'
import { RecProps } from '../types'
import { BoundedContent } from '@vp/swan'
import { newRelicAddPageAction } from '../utils/newRelic'
import { getPricingContext, listenToContextChangedEvent, listenToInitializedEvent } from '../utils/pricing'
import { v2RecommendationsGroup, recentlyViewed, stylesheet } from '../styles.scss'
import { useStyles } from '@vp/ubik-context'

const NEW_RELIC_PAGE_ACTION_NAME = 'recommendations loaded'

export default function RecommendationsTiles ({ rowType, fallbackData, locale, tenant }: RecProps) {
  const [hideRecommendations, setHideRecommendations] = useState(false)
  const [, setVatInclusive] = useState(true)
  const [, setPricingContext] = useState('')
  useEffect(() => {
    listenToInitializedEvent(event => {
      setPricingContext(getPricingContext())
      setVatInclusive(event.vatInclusive)
    })
    listenToContextChangedEvent(event => {
      setVatInclusive(event.vatInclusive)
      setPricingContext(getPricingContext())
    })
  }, [tenant, locale])

  if (hideRecommendations || fallbackData === null) {
    return null
  }
  if (locale === undefined || tenant === undefined) {
    console.log(`error tentant: ${tenant} or locale: ${locale} is undefined`)
    return
  }
  useStyles(stylesheet)
  return (
    <PricingContextProvider>
      <BoundedContent className={`${v2RecommendationsGroup} ${rowType === 'homepage' ? recentlyViewed : ''}`}>
        <RecommendationsWithoutProduct
          page={rowType}
          tenant={tenant}
          locale={locale}
          imageWidth={600}
          options={{ noSpinner: true }}
          fallbackData={fallbackData}
          successCallback={() => {
            newRelicAddPageAction(NEW_RELIC_PAGE_ACTION_NAME, { result: 'success' })
          }}
          errorCallback={() => {
            setHideRecommendations(true)
            newRelicAddPageAction(NEW_RELIC_PAGE_ACTION_NAME, { result: 'error' })
          }}
        />
      </BoundedContent>
    </PricingContextProvider>
  )
}
