import pricingContextModuleHelper from '@vp/pricing-context-module/helpers'

const getPricingContext = () => {
  return getPricingContextModule().getEncodedContextString()
}

const getPricingContextModule = () => {
  return document.pcxtV3
}

const listenToContextChangedEvent = callback => {
  pricingContextModuleHelper.executeAfterPricingContextEvent(pricingContextModuleHelper.EVENTS.CONTEXT_CHANGE_EVENT, callback, true)
}

const listenToInitializedEvent = callback => {
  pricingContextModuleHelper.executeAfterPricingContextEvent(pricingContextModuleHelper.EVENTS.INITIALIZED_EVENT, callback, true)
}

export { getPricingContext, listenToContextChangedEvent, listenToInitializedEvent }
