export const newRelicAddPageAction = (pageActionName, context, retry = false) => {
  if (window && window.newrelic && typeof window.newrelic.addPageAction === 'function') {
    window.newrelic.addPageAction(pageActionName, context)
  } else if (!retry) {
    // There are occasionally timing issues with the initialization of window.newrelic
    // this will retry once to help mitigate
    setTimeout(() => {
      newRelicAddPageAction(pageActionName, context, true)
    }, 2000)
  }
}
